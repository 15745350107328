import React from 'react'
import { Link } from 'gatsby';
import { ArrowLeftIcon } from '@heroicons/react/outline';

export default function Warning() {
  return (
    <>
      <div className="bg-gray-200 bg-opacity-30 rounded-md rounded-b-none text-primary-dark-blue px-6 py-6 my-6 flex flex-col items-center justify-center">
        <h2 className="uppercase text-center font-display font-bold text-2xl py-4 text-primary-dark-blue">
          Aún no has hecho una cotización.
        </h2>
        <div className="flex-col my-6">
          <Link
            to="/cotizar-envio"
            className="flex text-primary-light-red w-full items-center justify-center my-4 text-base font-semibold"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-2" />
            <span>Pedir Cotización</span>
          </Link>
        </div>
      </div>
    </>
  );
}
