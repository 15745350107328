import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
// (selected &&
//   "bg-primary-light-green") ||
// "bg-gray-200"
const defaultClasses =
  "flex justify-center items-center border-2 border-gray-300 text-center flex-shrink-0 rounded-full h-5 w-5";

const SelectBox = ({
  title,
  nombre,
  telefono,
  direccion,
  selected = true,
  handleClick,
}) => {
  return (
    <div
      onClick={handleClick}
      id={title}
      className="gap-x-0 sm:gap-x-4 rounded-xl p-6 capitalize cursor-pointer border-2 mt-2"
      style={selected ? { background: "#F1F1F1" } : { background: "#F9FAFB" }}
    >
      <div className=" leading-7 font-bold text-gray-700">
        <div className="flex justify-between">
          <span className="text-base uppercase text-primary-dark-green">
            {title}
          </span>
          <div
            className={`${defaultClasses} ${
              selected ? "bg-primary-light-green" : "bg-gray-200"
            }`}
          >
            {selected && (
              <CheckIcon
                className={
                  (selected && "h-5 w-5 text-green-100") ||
                  "h-5 w-5 text-gray-800"
                }
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        <p>
          Nombre: <span className="text-gray-500">{nombre}</span>
        </p>
        <p>
          Celular: <span className="text-gray-500">{telefono}</span>
        </p>
        <p>
          Dirección: <span className="text-gray-500">{direccion}</span>
        </p>
      </div>
    </div>
  );
};

export default SelectBox;
