import { useEffect, useState } from "react";
import { MexicanStates } from "../components/helpers";

export const useSetStates = ({ globalState, step }) => {
  const { quote, senderDetails, receiverDetails } = globalState;
  const [isStateEnabled, setIsStateEnabled] = useState(false);
  const [mexicanStates, setMexicanStates] = useState([]);
  const [currentState, setCurrentState] = useState(null);

  useEffect(() => {
    if (step === null) {
      return;
    }
    let _mexicanStates = MexicanStates.map((el) => ({ name: el, id: el }));
    let _lookupState =
      step === "senderDetails" ? quote.params.origin : quote.params.destination;
    if (!_lookupState && step === "senderDetails") {
      setIsStateEnabled(true);
      _lookupState = senderDetails.state || null;
    } else if (!_lookupState && step === "receiverDetails") {
      setIsStateEnabled(true);
      _lookupState = receiverDetails.state || null;
    }
    setMexicanStates(_mexicanStates);
    setCurrentState(_mexicanStates.find((el) => el.name === _lookupState));
  }, [
    setMexicanStates,
    quote.params,
    senderDetails.state,
    receiverDetails.state,
  ]);
  return { isStateEnabled, mexicanStates, currentState };
};
