import * as React from "react";
import { useStore } from "app/store/store";
import InputWithLabel from "./input-with-label";
import Select from "./select";
import { useSetStates } from "../../hooks/useSetStates";

const AddressFields = ({
  details = {},
  step = null,
  state = {},
  showMore = true,
}) => {
  const [globalState] = useStore();
  const { isStateEnabled, mexicanStates, currentState } = useSetStates({
    globalState,
    step,
  });
  // destructure details
  const {
    street,
    exterior,
    interior,
    colony,
    city,
    municipality,
    postalcode,
    reference_street,
    place_type,
  } = details;
  const _residenceType = ["Residencia", "Oficina", "Comercio", "Otro"].map(
    (el) => ({ name: el, id: el })
  );
  return (
    <div className="space-y-3 mt-4">
      <div className="md:grid md:grid-cols-2 md:gap-6 space-y-3 md:space-y-0">
        <InputWithLabel name="street" label="Calle(s)*" value={street} />
        <div className="flex justify-between space-x-4">
          <div className="md:w-1/2">
            <InputWithLabel
              name="exterior"
              label="No. Exterior"
              value={exterior}
            />
          </div>
          <div className="md:w-1/2">
            <InputWithLabel
              name="interior"
              label="No. Interior"
              value={interior}
            />
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-3 md:gap-3 space-y-3 md:space-y-0 md:mt-3">
        <InputWithLabel name="colony" label="Colonia*" value={colony} />
        <InputWithLabel name="city" label="Ciudad*" value={city} />
        <InputWithLabel
          name="municipality"
          label="Delegación o Municipio*"
          value={municipality}
        />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-6 space-y-3 md:space-y-0 md:mt-3">
        <InputWithLabel
          name="postalcode"
          type="number"
          label="Código Postal*"
          value={postalcode}
        />
        {showMore ? (
          <Select
            name="state"
            label="Estado*"
            placeholder="Estado"
            selectData={mexicanStates}
            selectedItem={currentState}
            disabled={step && !isStateEnabled && true}
            value={state}
          />
        ) : (
          ""
        )}
      </div>
      {showMore ? (
        <div className="md:grid md:grid-cols-2 md:gap-6 space-y-3 md:space-y-0 md:mt-3">
          <Select
            name="place_type"
            label="Tipo de Domicilio"
            placeholder="Tipo de Domicilio"
            selectData={_residenceType}
            selectedItem={_residenceType.find((el) => el.name === place_type)}
          />
          <InputWithLabel
            name="reference_street"
            label="Calles de Referencia"
            value={reference_street}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddressFields;
