export const MEXICAN_STATES = [
  "Baja California Norte",
  "Baja California Sur",
  "Ciudad de México",
  "Estado de México",
  "San Luis Potosí",
  "Aguascalientes",
  "Quintana Roo",
  "Guanajuato",
  "Nuevo León",
  "Tamaulipas",
  "Chihuahua",
  "Michoacán",
  "Querétaro",
  "Zacatecas",
  "Coahuila",
  "Campeche",
  "Guerrero",
  "Tlaxcala",
  "Veracruz",
  "Chiapas",
  "Durango",
  "Hidalgo",
  "Jalisco",
  "Morelos",
  "Nayarit",
  "Sinaloa",
  "Tabasco",
  "Yucatán",
  "Colima",
  "México",
  "Oaxaca",
  "Puebla",
  "Sonora",
];
