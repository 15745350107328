import * as React from "react";
import PageTitle from "../global/page-title";
import InputWithLabel from "./input-with-label";
import InputWithLabelAndPrefix from "./input-with-label-and-prefix";
import mxIcon from "images/mx.svg";
import AddressFields from "./address-fields";
import { classNames } from "../helpers";

const SubjectDetails = ({ step, details, active = true, showMore = true }) => {
  const { first_name, last_name, email, phone } = details;
  return (
    <>
      <div className="space-y-3">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <InputWithLabel
            name="first_name"
            label="Nombre(s)*"
            type="text"
            value={first_name}
          />
          <InputWithLabel
            name="last_name"
            label="Apellido(s)*"
            type="text"
            value={last_name}
          />
          <InputWithLabelAndPrefix
            name="phone"
            label="Teléfono/Celular*"
            prefix={
              <>
                <img src={mxIcon} alt="mx" className="w-4 h-4" />
                <span className="text-xs ml-1">+52</span>
              </>
            }
            type="tel"
            value={parseInt(phone) || null}
          />
          <InputWithLabel
            name="email"
            label="Correo Electrónico*"
            type="email"
            value={email}
          />
        </div>
      </div>
      {showMore ? (
        <PageTitle>
          Dirección de {step === "senderDetails" ? "recolección" : "envío"}
        </PageTitle>
      ) : (
        ""
      )}
      <div
        className={classNames(
          "",
          !active ? "pointer-events-none opacity-60 relative" : ""
        )}
      >
        <AddressFields details={details} step={step} showMore={showMore} />
        {!active && (
          <div className="absolute inset-0 bg-white opacity-50 pointer-events-auto -mt-3"></div>
        )}
      </div>
    </>
  );
};

export default SubjectDetails;
