import React from "react";
import InputWithLabel from "./input-with-label";
import Select from "./select";
import { cfdi, regimenFiscal } from "../helpers";
import { MEXICAN_STATES } from "../facturacion/data";

export const Billing = ({ dataFacturacion }) => {
  const { rfc, factura, regimen, estado } = dataFacturacion;
  const _usoFactura = cfdi.map((item) => ({ name: item, id: item }));
  const _regimenFiscal = regimenFiscal.map((item) => ({
    name: item,
    id: item,
  }));
  const _mexicanStates = MEXICAN_STATES.map((item) => ({
    name: item,
    id: item,
  }));
  return (
    <div className="mt-2 md:grid md:grid-cols-2 md:gap-6 space-y-3 md:space-y-0 md:mt-3 capitalize">
      <Select
        name="estado"
        label="Región/Estado*"
        placeholder="BAJA CALIFORNIA NORTE"
        selectData={_mexicanStates}
        selectedItem={_mexicanStates.find((el) => el.name === estado)}
        value={factura}
      />
      <InputWithLabel name="rfc" label="RFC *" type="text" value={rfc} />
      <Select
        name="factura"
        label="Uso de factura*"
        placeholder="G03 - Gastos en general."
        selectData={_usoFactura}
        selectedItem={_usoFactura.find((el) => el.name === factura)}
        value={factura}
      />
      <Select
        name="regimen"
        label="Régimen fiscal*"
        placeholder="601 - General de Ley Personas Morales"
        selectData={_regimenFiscal}
        selectedItem={_regimenFiscal.find((el) => el.name === regimen)}
        value={regimen}
      />
    </div>
  );
};
