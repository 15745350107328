import React, { useEffect, useState } from "react";
import Layout from "../components/global/layout";
import Navbar from "../components/global/navbar";
import PageTitle from "../components/global/page-title";
import { setStateVisitPage } from "../components/helpers";
import SelectBox from "../components/facturacion/SelectBox";
import SubjectDetails from "../components/forms/subject-details";
import { Billing } from "../components/forms/billing";
import * as validate from "validate.js";
import JSZip from "jszip";
import eventBus from "../app/services/eventBus";
import { DownloadIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { Link, navigate } from "gatsby";
import {
  shippingFieldsValidation,
  billingFieldsValidation,
} from "../app/services/validation";
import {
  ErrorList,
  Warning as WarningErrors,
} from "../components/forms/warning";
import Warning from "../components/Warning";
import { loginFacturacion, validateCP, createInvoice } from "../app/services/backend";

const hasfieldsErrors = (errors) => {
  if (!errors.length) return;
  return errors.join("\n");
};

const RealizarFactura = () => {
  // const [globalState] = useStore();
  const [fieldsErrors, setFieldsErrors] = useState([]);
  // const guide = !isEmptyObject(globalState.guide) ? globalState?.guide : null;
  const [selectedOrigen, setSeletecOrigen] = useState(false);
  const [selectedDestino, setSeletecDestino] = useState(false);
  const [dataOrigen, setDataOrigen] = useState({});
  const [dataDestino, setDataDestino] = useState({});
  const [dataFacturacion, setDataFacturacion] = useState({});
  const [submit, setSubmit] = useState(false);
  const [costo, setCosto] = useState();
  const [states, setStates] = useState();
  // * validacion y login de la facturacion
  const [login, setLogin] = useState(false)
  // ! Login y CP facturacion
  useEffect(() => {
    if (login) {
      const loginFactura = async () => {
        const { response, message } = await loginFacturacion()
        console.log("res login", response, message)
        if (response) {
          setLogin(true)
        }
      }
      loginFactura()
    }

  }, [login])
  const handleClickOrigen = (e) => {
    setSeletecDestino(false);
    setSeletecOrigen(!selectedOrigen);
    setDataFacturacion(dataOrigen);
  };
  const handleClickDestino = (e) => {
    setSeletecOrigen(false);
    setSeletecDestino(!selectedDestino);
    setDataFacturacion(dataDestino);
  };

  useEffect(() => {
    if (submit) {
      eventBus.publish("NOTIFICATION", {
        type: "info",
        title: "Generando Factura",
        message: "Espere un momento por favor...",
      });
    }
  }, [submit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldsErrors("");
    // // const validateCPG = async () => {
    // const { validateAddress } = await validateCP({ dataFacturacion })
    // const res = validateAddress.filter(item => item.response === false)
    // if (res) {
    //   res.map(item => {
    //     if (item.messageCP) {
    //       setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageCP]]);
    //     }
    //     if (item.messageColonia) {
    //       setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageColonia]]);
    //     }
    //     if (item.messageMunicipio) {
    //       setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageMunicipio]]);
    //     }
    //   })
    // }
    // setAddressSat(validateAddress)
    // }
    // validateCPG()
    const fieldsValidated = validate(dataFacturacion, {
      ...shippingFieldsValidation,
      ...billingFieldsValidation,
    });
    // * Check if data it's valid
    if (fieldsValidated !== null && fieldsValidated !== undefined) {
      // setFieldsErrors(Object.values(fieldsValidated));
      Object.values(fieldsValidated).map(item => (
        setFieldsErrors((fieldsErrors) => [...fieldsErrors, (item)])
      ))
      navigate("#form");
    } else {
      setFieldsErrors("");
      const { validateAddress, dataAddress } = await validateCP({ dataFacturacion })
      const res = validateAddress.filter(item => item.response === false)
      if (res.length > 0) {
        setSubmit(false);
        res.map(item => {
          if (item.messageEstado) {
            setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageEstado]]);
          }
          if (item.messageCP) {
            setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageCP]]);
          }
          if (item.messageColonia) {
            setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageColonia]]);
          }
          if (item.messageMunicipio) {
            setFieldsErrors((fieldsErrors) => [...fieldsErrors, [item.messageMunicipio]]);
          }
        })
        navigate("#form");
      } else {
        setFieldsErrors("");
        // ? Todo salio bien mandamos una notificación informando que se esta realizando la cotización
        setSubmit(true);
        console.log(dataFacturacion)
        const sendData = await createInvoice(dataFacturacion, costo, states, dataAddress);
        if (sendData?.type === "error") {
          setSubmit(false);
          eventBus.publish("NOTIFICATION", sendData);
        } else {
          try {
            const blob = sendData;
            // Crea una nueva instancia de JSZip y carga el contenido del archivo ZIP
            const zip = new JSZip();
            const zipData = await zip.loadAsync(blob);
            // Itera sobre cada archivo dentro del ZIP y guarda los datos en el sistema de archivos del usuario
            for (const relativePath in zipData.files) {
              const zipEntry = zipData.files[relativePath];
              if (!zipEntry.dir) {
                const content = await zipEntry.async("blob");
                const blob = new Blob([content], {
                  type: "application/octet-stream",
                });
                const objectUrl = URL.createObjectURL(blob);
                // Crea un enlace de descarga y simula un clic en él para descargar el archivo
                const link = document.createElement("a");
                link.href = objectUrl;
                link.download = zipEntry.name;
                document.body.appendChild(link);
                link.click();
                // Elimina la URL temporal
                URL.revokeObjectURL(objectUrl);
              }
            }
            setSubmit(false);
            eventBus.publish("NOTIFICATION", {
              title: "Factura lista!",
              message: "Ya tenemos tu factura",
              type: "success",
            });
            if (!submit) {
              const timer = setTimeout(() => {
                setStateVisitPage(true);
                navigate("/pago-completado/");
              }, 4000);
              return () => clearTimeout(timer);
            }
            console.log("SB", submit);
          } catch (err) {
            console.log("ERR", err);
            eventBus.publish("NOTIFICATION", {
              title: "Error",
              type: "error",
              message: "Error al descargar o descomprimir el archivo",
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    try {
      const dataLocal = JSON.parse(sessionStorage.getItem("allDataDetails"));
      setDataOrigen(dataLocal.sender);
      setDataDestino(dataLocal.receiver);
      setCosto(dataLocal.costo);
      setStates({ origen: dataLocal.origen, destino: dataLocal.destino });
      if (!selectedOrigen && !selectedDestino) {
        setDataFacturacion(dataLocal.sender);
      }
      return;
    } catch (err) {
      // TODO: Enviar mensaje
      console.error("Debes realizar");
    }
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setDataFacturacion({ ...dataFacturacion, [name]: value });
  };

  return (
    <Layout>
      <Navbar />
      <PageTitle>Facturación</PageTitle>
      {
        (Object.keys(dataOrigen).length,
          Object.keys(dataDestino).length ? (
            <>
              <p className="mt-2 text-gray-700">
                Selecciona o ingresa los datos de facturación
              </p>
              <div>
                <div className="mx-auto max-w-7xl py-5">
                  <div className="mx-auto grid grid-cols-1 gap-0 lg:max-w-none lg:grid-cols-2 md:gap-2">
                    {dataOrigen && (
                      <>
                        <SelectBox
                          title="Quíen Envía"
                          nombre={dataOrigen.first_name}
                          telefono={dataOrigen.phone}
                          direccion={`${dataOrigen.street}, ${dataOrigen.city}, ${dataOrigen.postalcode}, ${dataOrigen.municipality}, ${states?.origen}`}
                          selected={selectedOrigen}
                          handleClick={handleClickOrigen}
                        />
                        <SelectBox
                          title="Quíen Recibe"
                          nombre={dataDestino.first_name}
                          telefono={dataDestino.phone}
                          direccion={`${dataDestino.street}, ${dataDestino.city}, ${dataDestino.postalcode}, ${dataDestino.municipality}, ${states?.destino}`}
                          selected={selectedDestino}
                          handleClick={handleClickDestino}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} onChange={handleChange} id="form">
                <PageTitle>Datos de Facturación</PageTitle>
                <div className="mt-4"></div>
                {hasfieldsErrors(fieldsErrors) && (
                  <WarningErrors>
                    <ErrorList errors={fieldsErrors} />
                  </WarningErrors>
                )}
                {!selectedDestino && !selectedOrigen ? (
                  <>
                    <SubjectDetails details={dataOrigen} showMore={false} />
                    <Billing dataFacturacion={dataFacturacion} />
                  </>
                ) : (
                  ""
                )}
                {selectedOrigen ? (
                  <>
                    <SubjectDetails details={dataOrigen} showMore={false} />
                    <Billing dataFacturacion={dataFacturacion} />
                  </>
                ) : (
                  ""
                )}
                {selectedDestino ? (
                  <>
                    <SubjectDetails details={dataDestino} showMore={false} />
                    <Billing dataFacturacion={dataFacturacion} />
                  </>
                ) : (
                  ""
                )}
                <div className="flex-col my-6">
                  <div className="flex flex-nowrap space-x-4 mx-4 justify-center">
                    <Link
                      to="/pago-completado"
                      className="btn-shape bg-primary-light-red text-white"
                    >
                      <ArrowLeftIcon className="w-4 h-4 stroke-current mr-1" />
                      Regresar
                    </Link>
                    <button className="btn-shape bg-primary-light-red text-white w-1/2 flex justify-evenly">
                      Generar Factura
                      <DownloadIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </form>
              {/* Corregir del footer */}
              <div className="mt-36"></div>
            </>
          ) : (
            <Warning />
          ))
      }
    </Layout>
  );
};

export default RealizarFactura;
