import * as React from 'react'

import { classNames } from '../helpers'


const InputWithLabelAndPrefix = ({ label, labelIsTitle, type, name, prefix, placeholder, value, ...props }) => {
    return (
        <>
            <div >
                <label htmlFor={name}
                    className={classNames('block text-xsm font-medium text-primary-dark-blue', (labelIsTitle ? 'base-title text-primary-gray' : ''))}>
                    {label}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        {prefix}
                    </span>
                    <input
                        defaultValue={value}
                        type={type ? type : "text"}
                        name={name}
                        id={name}
                        className="input-field rounded-none rounded-r-md"
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </>
    )
}

export default InputWithLabelAndPrefix